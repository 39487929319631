<template>
  <a-layout-header style="height: 39px">
    <a-page-header
      :show-back="false"
      class="page-header"
      :style="{
        background: 'var(--color-bg-2)',
        height: '55px',
        borderBottom: '1px solid rgba(187, 187, 187, .5)',
        backgroundColor: 'black',
      }"
    >
      <template v-if="isShowExtra" #extra>
        <a-space>
          <div style="color: white"></div>
        </a-space>

        <a-space>
          <a-space size="mini">
            <template v-if="user">
              <a-button
                type="text"
                size="mini"
                style="color: rgba(16, 16, 16, 1); margin-left: 25px"
              >
                <div style="color: white">欢迎您 {{ user.no }}</div>
              </a-button>
            </template>
            <template v-else>
              <a-button
                type="text"
                size="mini"
                style="color: rgba(16, 16, 16, 1); margin-left: 25px"
                @click="onLogin"
                ><div style="color: white">请登陆</div>
              </a-button>
              <a-divider direction="vertical" margin="2px" />
              <a-button
                type="text"
                size="mini"
                style="color: rgba(16, 16, 16, 1)"
                @click="onRegister"
                ><div style="color: white">请注册</div>
              </a-button>
            </template>
          </a-space>
          <a-space size="mini">
            <a-button
              type="text"
              size="mini"
              style="color: rgba(16, 16, 16, 1)"
              @click="toUserCenter"
              v-show="user"
            >
              <span style="color: white">个人中心</span>
            </a-button>
          </a-space>
        </a-space>
      </template>
    </a-page-header>
  </a-layout-header>
</template>
<script>
import { useRouter } from "vue-router";
import { useStorage } from "@vueuse/core";
export default {
  props: {
    isShowExtra: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const user = useStorage("user", null, undefined, {
      serializer: {
        read: (v) => (v ? JSON.parse(v) : null),
        write: (v) => JSON.stringify(v),
      },
    });
    const onLogin = () => {
      router.push({
        name: "Login",
      });
    };

    const onRegister = () => {
      router.push({
        name: "Register",
      });
    };

    const toUserCenter = () => {
      router.push({
        name: "OrderData",
      });
    };

    return {
      onLogin,
      onRegister,
      user,
      toUserCenter,
    };
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  ::v-deep {
    .arco-page-header-extra {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-top: -25px;
    }
  }
}
</style>
