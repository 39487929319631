<template>
  <a-layout>
    <!--     <page-header style="position: fixed; width: 100%" />-->
    <!--        <a-layout-content >-->

    <!--        </a-layout-content>-->
    <!--        <a-layout-footer>-->
    <!--            <good-footer />-->
    <!--        </a-layout-footer>-->

    <img src="../../assets/home_bg.jpg" style="width: 100%; height: 100%" />

    <div
      style="
        display: flex;
        position: fixed;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 23%;
        width: 100%;
        align-items: center;
      "
    >
      <div
        v-for="item in homeData"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          :style="{
            background: item.color,
            width: '40px',
            height: '12px',
            borderRadius: '3px',
            marginBottom: '20px',
          }"
        ></div>
        <div v-for="tag in item.tags">
          <span
            style="
              font-size: 18px;
              color: white;
              font-weight: bold;
              line-height: 30px;
            "
            >{{ tag }}</span
          >
        </div>
      </div>
    </div>

    <!--      <div style="display: flex; flex-direction: row; justify-content:space-around; position:fixed;">-->
    <!--        <div style="background-color: red; width: 20%">-->
    <!--          <span>1111</span>-->
    <!--        </div>-->
    <!--        <div style="background-color: green; width: 20%">-->
    <!--          <span>2222</span>-->
    <!--        </div>-->
    <!--      </div>-->
  </a-layout>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import { onBeforeMount, shallowRef, unref } from "vue";
import useBanner from "./js/useBanner.js";
import useList from "./js/useList.js";
import { typeList } from "@/http/typeList.js";
import { useRouter } from "vue-router";
import { errorNotification } from "@/utils/notification.js";
import LabelGood from "./LabelGood.vue";

export default {
  components: {
    PageHeader,
    LabelGood,
  },
  setup() {
    const router = useRouter();
    const goodName = shallowRef("");
    const { list, getBannerList } = useBanner();

    const { list: lossList, getBannerList: lossBannerList } = useBanner(3);

    const { list: typeListRef, getList } = useList(async () => {
      return await typeList();
    });

    const onSearch = () => {
      const name = unref(goodName);
      if (!name) {
        errorNotification({ content: "请输入商品名称" });
        return;
      }
      router.push({
        name: "GoodsList",
        query: {
          goodName: name,
        },
      });
    };

    const toGoodDetail = (id) => {
      router.push({
        name: "GoodDetail",
        query: {
          id,
        },
      });
    };

    onBeforeMount(() => {
      getBannerList();
      getList();
      lossBannerList();
    });

    return {
      images: list,
      typeListRef,
      lossList,
      goodName,
      onSearch,
      toGoodDetail,
      homeData: [
        {
          title: "首页",
          tag: 0,
          color: "#94534d",
          tags: ["20岁", "汉族", "狮子座"],
        },
        {
          title: "分类",
          tag: 1,
          color: "#4697c2",
          tags: ["不太爱运动", "喜欢打游戏", "喜欢看电影", "活泼赛丽亚"],
        },
        {
          title: "明细",
          tag: 2,
          color: "#6e9b5e",
          tags: ["有个性", "爱干净", "喜欢自家旅游"],
        },
        {
          title: "明细",
          tag: 3,
          color: "#d987d5",
          tags: ["爱读书", "乐于助人", "帅气"],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-image: url("../../../public/icon.png");
}

.icon2 {
  background-position: -51px;
}

.icon3 {
  background-position: -101px;
}

.icon4 {
  background-position: -151px;
}

.icon5 {
  background-position: -210px;
}
</style>
